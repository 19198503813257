.signup {

    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(.meal-options){
            position: relative;
            z-index: 1; // to prevent dropdown from hiding behind next section.
        }
                
        .col {
            // padding: 0 1em;
            h4 { text-align: left; margin-bottom: 0; font-size: 1.2em; }
        }
        @include md-up {
            flex-direction: row;
            justify-content: center;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            align-items: flex-start;
            .col {
                flex-basis: 50%;
                margin: 1em;
            }
        }
    }

    .col > div {
        display: inline-block;
        width: 100%;
        margin-bottom: .5em;
        vertical-align: top;
        &.short {
            width: calc(50% - .25em);
            & + .short {
                margin-left: .5em;
            }
        }
        &.error {
            color: $red;
            input::placeholder { color: $red };
            input[type=text], input[type=email] {
                border: 1px solid $red;
            }
        }
        input[type=text], input[type=email], .CustomSelect__control { font-size: 1.5em; }
    }
    input[type=text], input[type=email] {
        font-family: cormorant-garamond, serif;
        padding: .5em;
        display: block;
        width: 100%;
        box-sizing: border-box;
    }
    .CustomSelect__control {
        border-width: 2px;
        border-style: inset;
        border-color: unset;
        text-align: left;
        line-height: normal;
        border-radius: 0;
        padding: .5em;
        .CustomSelect__value-container { padding: 0; }
        .CustomSelect__input-container { padding: 0; margin: 0; }
        .CustomSelect__indicator-separator { display: none; }
        .CustomSelect__indicator { padding: 0; }
        .Dropdown-arrow {
            top: 0.8em;
        }
    }
    .CustomSelect__menu {
        margin: 0;
        border-radius: 0;
        .CustomSelect__menu-list { padding: 0; }
        .CustomSelect__option { text-align: left; color: $black; }
    }
    div.wrap-checkbox {
        display:flex;
        align-items: flex-end;
        input { order:1; width: 2em; height: 2em; margin-right: 2em; }
        label { order:2; }
    }

    .green-button {
        background-color: $green;
        border: 1px solid $white;
        color: $white;
        &:hover, &.selected {
            background-color: $bronze;
            color: $white;
        }
    }
    .blue-button {
        background-color: $blue;
        border: 1px solid $white;
        color: $white;
        &:hover, &.selected {
            background-color: $bronze;
            color: $white;
        }
    }
    input[type=submit].black-button {
        background-color: $black;
        border: 1px solid $white;
        color: $white;
        &:hover, &.selected {
            background-color: $bronze;
            color: $white;
        }
    }

    h3 {
        font-size: 2em;
        margin-bottom: 0;
        &.error {
            color: $red;
        }
    }
    p.error {
        color: $red;
    }
    .meal-options {
        > div {
            width: 290px;
            margin: 0 1em;
        }
    }
    .radios {
        margin-top: 3em;
        margin-bottom: 1em;
        align-items: flex-start;
        > div {
            display: flex;
            max-width: 280px;
            margin: 1em;
            align-items: flex-start;
            text-align: left;
            &.error {
                color: $red;
            }
        }
    }

    .mini-countdown {
        color: $bronze;
        font-weight: 700;
    }
}