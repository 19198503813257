$bronze: #a57133;
$green : #394213;
$blue: #2a4448;
$black: #0a0a0a;
$white: #ffffff;
$red: #ff0000;
$gray: #3d3d3d;

// these align with bootstrap breaks
@mixin sm-up() {
    @media (min-width: 576px) {
        @content;
    }
}
@mixin md-up() {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin lg-up() {
    @media (min-width: 992px) {
        @content;
    }
}
@mixin xl-up() {
    @media (min-width: 1200px) {
        @content;
    }
}