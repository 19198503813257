@import './scss/variables';
@import './scss/fonts';
@import './scss/splash';
@import './scss/signup';
@import './scss/thankyou';
@import './scss/confirm';

.App {
  background-color: #000;
  background-image: url('./assets/TROP-website-desktop.jpg');
  // background-size: 100% auto;
  background-repeat: repeat-y;
  background-position: center top;
  text-align: center;
  min-height: 100vh;
  font-family: cormorant-garamond, serif;
  font-size: 1.1rem;
  line-height: 1.3;
  color: $white;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 10em; // account for footer;

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.1;
    width: 100%;
  }
  p {
    font-weight: 300;
    // width: 100%;
    a { color: $bronze; }
  }
  > div:not(.cookie-consent-overlay) {
    padding-left: 2em;
    padding-right: 2em;
  }
  img {
    // max-width: 100%;
  }
  button, input[type=submit], .button {
    font-family: cormorant-garamond, serif;
    font-weight: 700;
    font-size: 1.2em;
    display: block;
    padding: .8em;
    width: 280px;
    background-color: $bronze;
    border: none;
    border-radius: 2em;
    margin: 1em auto;
    cursor: pointer;
    transition: background-color .3s;
    color: $black;
    &:hover {
      background-color: $white;
    }

    &:disabled {
      background-color: $gray;
      pointer-events: none;
    }
  }
  input[type=text], input[type=email] { 
  }

  h1 {
    color: $bronze;
    font-weight: 700;
    font-size: 2em;
    @apply md-up {
      font-size:3em;
    }
  }

  .hidden { display: none; }
}



// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

.App-header {
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  color: white;

  .AMZ-logo {
    width: 120px;
    max-width: 33%;
    pointer-events: none;
    margin-bottom: 4em;
  }
  .LOTR-logo {
    max-width: 70%;
    width: 457px;
    display: none;
    cursor: pointer;
    @include md-up {
      display: block;
    }
  }
  .LOTR-logo-mb {
    max-width: 70%;
    width: 240px;
    @include md-up {
      display: none;
    }
  }

  .toggle {
    position: absolute;
    background: none;
    padding: 0;
    width: unset;
    color: $white;
    top: .8em;
    right: 2em;
    &:hover {
      color: $bronze;
      background: none;
    }
  }
}
.App-footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background-color: $black;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  li { 
    list-style: none;
    display: inline-block;
    padding: .25em;
    flex-basis: 50%;
    box-sizing: border-box;
    a{
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @include md-up {
    display: block;
    li {
      padding: 1em;
      flex-basis: unset;
    }
  }

}

.countdown {
  h2 {
    font-family: collier, sans-serif;
    font-weight: 600;
    font-style: normal;
    line-height: .6;
    margin: .5rem 0 0;
    font-size: 3.5em;
    margin-bottom: 3rem;
    small { font-family: cormorant-garamond, serif;
      font-weight: 700;
      font-size: .4em; }
    @include sm-up {
      font-size: 5em;
      small { font-size: .6em; }
    }
  }
  ul {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    li {
      list-style: none;
      font-family: collier, sans-serif;
      font-weight: 600;
    }
  }
}

.cookie-consent-overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}
.cookie-consent {
  background: $bronze;
  color: $white;
  padding: 1em;
  position: fixed;
  bottom: 0;
  font-size: .8em;
  // margin-top: -150px;
  width: 100%;
  box-sizing: border-box;
  div p a {
    color: $white;
  }
  .cookie-consent-buttons {
    button {
      color: $white;
      border: 1px solid white;
      &:hover {
        color: $black;
      }
    }
  }
}

.App-link {
  color: #61dafb;
}



.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  color: $white;
  padding: 2em;
  width: 100%;
  // max-width: 100%;
  margin-left: -50%;
  margin-top: -150px;
  z-index: 9999;
  box-sizing: border-box;
  @include md-up {
    width: 500px;
    margin-left: -250px;
  }

  button {
    color: $white;
    border: 1px solid white;
    &:hover {
      color: $black;
    }
  }
  &:before {
    content:'';
    background: $bronze;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: -1;
  }
  &:after {
    content:'';
    background: #0a0a0a99;
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: -2;
  }
}