.splash {
    img, p, ul {
        width: 652px;
        max-width: calc(100% - 4em);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1em;
    }
    p {
        width: 435px;
        i {
            white-space: nowrap;
        }
    }
    h3 {
        color: $bronze;
        font-weight: 700;
        font-size: 1.8em;
    }
    
    img {
        margin: 2rem auto;
    }

    .mini-countdown { display: none; }
}
.faq-body {
    h4 { margin-bottom: .5em; }
    p { margin-top: .5em;
        width: 435px;
        max-width: calc(100% - 4em);
        margin-left: auto;
        margin-right: auto; }
}
.terms {
    text-align: left;
}

.delivery-areas {
    margin-top: 4em;
    margin-bottom: 2em;
    h4 {
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: .5em;
    }
    ul {
        width: 652px;
        max-width: calc(100% - 4em);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 auto;
        padding: 0;
        li {
            list-style: none;
            margin: 0;
            padding: .25em;
        }
        @include md-up {
            flex-direction: row;
        }
    }
}